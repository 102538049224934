<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-card-text class="text-right text-12">{{ prepend }}{{ range[0] }}</b-card-text>
      </b-col>

      <b-col cols="6">
        <b-card-text class="text-12">{{ prepend }}{{ range[1] }}</b-card-text>
      </b-col>
    </b-row>

    <vue-slider
      v-model="range"
      :min="min"
      :max="max"
      :dot-size="dotSize"
      :interval="interval"
      @error="onError"
      @change="onSliderChanged"
    />

    <b-row class="mt-1">
      <b-col cols="5" class="pr-0">
        <b-input-group
          :prepend="prepend"
          class="input-group-merge"
        >
          <b-form-input v-model="startRange" class="text-12" placeholder="100" type="number" @input="onStartChanged" />
        </b-input-group>
      </b-col>

      <b-col cols="2" class="d-flex align-items-center">
        <div class="" style="background-color: lightgray; height: 2px; width: 100%" />
      </b-col>

      <b-col cols="5" class="pl-0">
        <b-input-group
          :prepend="prepend"
          class="input-group-merge"
        >
          <b-form-input v-model="endRange" class="text-12" placeholder="100" type="number" @input="onEndChanged" />
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { BCol, BRow, BInputGroup, BFormInput, BCardText } from 'bootstrap-vue';

  import VueSlider from 'vue-slider-component';
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

  export default {
    components: {
      VueSlider,

      BRow,
      BCol,
      BCardText,
      BFormInput,
      BInputGroup, 
    },
    props: {
      value: {
        type: Array,
        required: true,
        default: () => ([])
      },
      min: {
        type: Number,
        default: 0
      },
      max: {
        type: Number,
        default: 1
      },
      interval: {
        type: Number,
        default: 1
      },
      dotSize: {
        type: Number,
        default: 20
      },
      prepend: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        range: [],
        endRange: 0,
        startRange: 0,
      }
    },
    created() {
      this.range = this.value;

      if (this.range.length === 2){
        const [start, end] = this.range
        this.endRange = end;
        this.startRange = start;
      }
    },
    methods: {
      onSliderChanged(range){
        if (Array.isArray(range) && range.length === 2){
          const [start, end] = range;
          this.endRange = end;
          this.startRange = start;

          this.$emit('change', range)
          this.$emit('input', this.range)
        }
      },
      onStartChanged(input){
        if (this.range.length === 2){
          this.range = [
            +input,
            this.range[1],
          ]
          this.$emit('change', this.range)
          this.$emit('input', this.range)
        }
      },
      onEndChanged(input){
        if (this.range.length === 2){
          this.range = [
            this.range[0],
            +input,
          ]
          this.$emit('change', this.range)
          this.$emit('input', this.range)
        }
      },
      onError(type, message){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Range Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: message,
          },
        });

        if (type === 3){
          // value is less than min 
          this.range = [
            this.min,
            this.range[1]
          ];
          this.startRange = this.min
        }

        if (type === 4){
          // value is greater than max 
          this.range = [
            this.range[0],
            this.max
          ];
          this.endRange = this.max;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
