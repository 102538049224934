<!-- eslint-disable vue/no-v-html -->
<template>
  <b-card
    no-body
    style="max-height: 380px;"
  >
    <div v-if="isLoggedIn" class="like-icon">
      <div v-if="isFavorite" class="liked-icon" @click="onRemoveFavorite(car._id)"> 
        <feather-icon icon="HeartIcon" size="20" color="white" />
      </div>

      <div v-else @click="onAddFavorite(car._id)">
        <feather-icon icon="HeartIcon" size="20" color="white" />
      </div>
    </div>

    <div class="blur-hash-container">
      <car-image-display
        width="360"
        height="170"
        alt="Car image"
        :src="getValueFromSource(car, 'car_images[0].image.path')"
        :hash="getValueFromSource(car, 'car_images[0].image.hash')"
      />
    </div>

    <div class="p-1 parent-div">
      <div>
        <h4 v-b-tooltip.hover class="text-dark ellipsis ellipsis-2" variant="text" :title="car.make_title">
          {{ car.car_model_year }} {{ car.brand_title }} {{ car.make_title }}
        </h4>
      </div>
  
      <!-- // eslint-disable-next-line vue/no-v-html -->
      <!-- <b-card-text class="text-muted ellipsis-4" v-html="car.description" /> -->
      <b-card-text class="text-muted ellipsis-4 mt-1 mb-1">
        {{ extractTextContentFromHTML(car.description) }}
      </b-card-text>
      
      <div class="footer-div">
        <hr>
        <div class="d-flex align-items-center justify-content-between">
          <h6 class="text-dark">
           <small class="text-muted">
            Starting From
           </small>  ${{ formatMoney(car.rate) }}/<small class="text-muted">day</small>
          </h6>
  
          <div>
            <b-link v-if="isHistory" @click="onRemoveFromHistory(car._id)">
              <feather-icon icon="TrashIcon" color="red" class="mr-1" />
            </b-link>
            <b-button :to="{ name: 'rental-details', params: { id: car._id } }" variant="primary" size="sm">Interested</b-button>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCol, BCard, BCardText, BLink, BButton, VBTooltip } from 'bootstrap-vue';

import { get } from "lodash";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CarImageDisplay from './CarImageDisplay.vue';

export default {
  components: {
    BCol,
    BCard, 
    BLink,
    BButton,
    BCardText,
    CarImageDisplay
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    car: {
      required: true,
      type: Object,
      default: () => ({})
    },
    md: {
      type: Number,
      default: 2
    },
    containerClass: {
      type: String,
      default: 'pl-0'
    },
    isFavorite: {
      required: false,
      type: Boolean,
    },
    isHistory: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      userData: {},
    }
  },
  methods: {
    async onAddFavorite(car_id) {
      try {
        this.loading = true;

        if (this.isFavorite){
          return
        }

        await this.useJwt().ristic.favoriteCars.add(car_id);
        this.$emit('refetchData')

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            variant: 'success',
            icon: 'CheckCircleIcon',
            text: `Car added to favorites successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onRemoveFavorite(car_id) {
      try {
        this.loading = true;

        await this.useJwt().ristic.favoriteCars.remove(car_id);
        this.$emit('refetchData')

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            variant: 'success',
            icon: 'CheckCircleIcon',
            text: `Car removed from favorites successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onRemoveFromHistory(car_id) {
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Removal?',
          text: "Do you want to remove this car from your history?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().ristic.carsViewedHistory.remove(car_id);
        this.$emit('refetchHistory')

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            variant: 'success',
            icon: 'CheckCircleIcon',
            text: `Car removed from history successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  }
  
}
</script>

<style lang="scss" scoped>
.car-display {
  position: relative;
}

.like-icon {
  position: absolute;
  top: 5px; 
  right: 5px;
  padding: 5px;
  border-radius: 50%; 
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
}

.liked-icon {
  background-color: #A1CB3A;
  padding: 5px;
  border-radius: 50%; 
}

.car-display:hover .like-icon {
  display: block;
}

.parent-div {
  height: 90%; 
  display: flex;
  flex-direction: column;
}

.footer-div {
  margin-top: auto;         
}

.blur-hash-container {
  overflow: hidden;
  border-radius: 8px 8px 0px 0px;
  height: 250px;
}
</style>
