import { pick, get } from "lodash";
import { differenceInCalendarDays } from "date-fns";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {},
  data() {
    return {
      loadingCars: false,
      loadingExtraCars: false,
      verifyingPayment: false,
      loadingBestOffers: false,
      cancellingPayment: false,
      loadingBookingLocations: false,
      hasMoreCars: false,
      currentViewedCar: null,

      favorite_cars: [],
      featured_cars: [],
      carsAvailableForRental: [],
      filterFields: ["price_range", "year_range", "car_types", "car_brands", "car_features"],
    };
  },
  computed: {
    cars() {
      const cars = this.$store.getters['ristic/cars'];

      return cars.map((car) => ({
        ...car,
        title: car.car_name,
        image: this.getFirstCarImage(car)
      }));
    },
    filters() {
      return this.$store.getters['ristic/filters'];
    },
    sort_cars_by: {
      get() {
        return this.$store.getters['ristic/sort_cars_by'] || "created_desc";
      },
      set(val) {
        this.currentPage = 1;
        this.$store.commit("ristic/SET_SORT_CAR_BY", val);
      }
    },
    carsAvailableForDisplay() {
      return this.cars.map(this.makeCarDisplayData);
    },
    minYear() {
      return this.$store.state.ristic.minYear
    },
    maxYear() {
      return this.$store.state.ristic.maxYear
    },
    minPrice() {
      return this.$store.state.ristic.minPrice
    },
    maxPrice() {
      return this.$store.state.ristic.maxPrice
    },
    carBrands() {
      return this.$store.state.ristic.carBrands
    },
    carTypes() {
      return this.$store.state.ristic.carTypes
    },
    carFeatures() {
      return this.$store.state.ristic.carFeatures
    },
    browseFilters() {
      return this.$store.state.ristic.filters
    },
    bestOfferCar() {
      return this.$store.state.ristic.bestOffer;
    },
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get() {
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    currentViewedCar: {
      handler(newCar) {
        this.fetchFeaturedCars(newCar.car_type_id);
      },
      immediate: false
    },
  },
  async created() {
    if (this.isLoggedIn && !this.isCurrentUserAdmin) {
      await this.fetchFavoriteCars()
    }

    const {
      page = 1,
    } = this.$route.query;

    this.currentPage = +page;
  },
  methods: {
    async markAsPickedUp(booking, cb) {
      try {
        this.loading = true;

        const confirmation = await this.$swal({
          title: 'Mark this car as picked up?',
          text: "You are about to mark this car as picked up by client. Continue?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!confirmation.value) {
          return;
        }

        await this.useJwt().adminService.carBookings.markAsPickedUp(booking._id);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Car booking has been marked as picked up.'
          },
        });
        cb && cb()
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async markAsReturned(booking, cb) {
      try {
        this.loading = true;

        const confirmation = await this.$swal({
          title: 'Mark this car as returned?',
          text: "You are about to mark the car associated with this booking as returned. Continue?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!confirmation.value) {
          return;
        }

        await this.useJwt().adminService.carBookings.markAsReturned(booking._id);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Car booking has been marked as returned.'
          },
        });
        cb && cb()
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchRisticFilters() {
      try {
        this.loading = true
        this.loadingCars = true
        const response = await this.useJwt().ristic.miscellaneous.fetchFilters({})
        const data = this.getValueFromSource(response, 'data.data')

        this.$store.commit('ristic/SET_BRANDS', data.car_brands || [])
        this.$store.commit('ristic/SET_CAR_TYPES', data.car_types || [])
        this.$store.commit('ristic/SET_CAR_FEATURES', data.car_features || [])

        const { min: min_year = 0, max: max_year = 0 } = get(data, 'year_range', {});
        const { min: min_price = 0, max: max_price = 0 } = get(data, 'price_range', {});

        this.$store.commit('ristic/SET_MIN_YEAR', min_year);
        this.$store.commit('ristic/SET_MAX_YEAR', max_year);
        this.$store.commit('ristic/SET_MIN_PRICE', min_price)
        this.$store.commit('ristic/SET_MAX_PRICE', max_price)

        // update filters 
        this.$store.commit('ristic/UPDATE_FILTER', { key: 'year_range', value: [min_year, max_year] })

        // user current route to update filters
        const { price_range = [] } = this.$route.query;
        const default_price_range = [min_price, max_price]

        const routePriceRangeIsValid = () => {
          const isArray = Array.isArray(price_range)
          const isLengthTwo = isArray && price_range.length === 2
          const isAllNumbers = isArray && price_range.every(p => !isNaN((parseFloat(p))))

          return isLengthTwo && isAllNumbers
        }

        const filtered_price_range = routePriceRangeIsValid() ? price_range.map(str => parseFloat(str)) : default_price_range

        this.$store.commit('ristic/UPDATE_FILTER', { key: 'price_range', value: filtered_price_range })
      } catch (error) {
        console.log("Error here", error)
        this.showError(error)
      } finally {
        this.loading = false
        this.loadingCars = false;
      }
    },
    async fetchCars(text, updateUrl = true) {
      try {
        this.loadingCars = true;
        const selectedFilters = this.sanitizeArrayFilters(pick(this.filters, this.filterFields));

        const query = {
          search: text || "",
          ...selectedFilters
        }
        const response = await this.useJwt().ristic.cars.fetch(
          query
        );

        updateUrl && await this.$router.push({ query }).catch(() => { });

        const data = this.getValueFromSource(response, "data.data");
        this.$store.commit("ristic/SET_CARS", data || []);
      } catch (error) {
        this.showError(error);
      } finally {
        this.loadingCars = false;
      }
    },
    async fetchRentalCars(text, isFetchMore = false) {
      try {
        if (isFetchMore) {
          this.loadingExtraCars = true
        } else {
          this.loadingCars = true
        }
        const selectedFilters = this.sanitizeArrayFilters(pick(this.filters, this.filterFields));

        const query = {
          search: text || "",
          limit: 20,
          page: this.currentPage,
          ...selectedFilters
        }

        if (this.sort_cars_by) {
          query.sort_by = this.sort_cars_by
        }
        const response = await this.useJwt().ristic.cars.fetch(
          query
        );

        this.$router.replace({ query }).catch(() => { });

        const data = this.getValueFromSource(response, "data.data");
        const pagination = this.getValueFromSource(response, "data.pagination");

        this.totalRecords = pagination.totalRecords;

        if (query.search !== "" || !isFetchMore) {
          this.carsAvailableForRental = data.map(this.makeCarDisplayData);
        } else {
          const uniqueCars = data.filter(car => !this.carsAvailableForRental.some(existingCar => existingCar._id === car._id));
          this.carsAvailableForRental.push(...uniqueCars);
          this.carsAvailableForRental = this.carsAvailableForRental.map(this.makeCarDisplayData);
        }
        this.hasMoreCars = !!pagination.nextPage
      } catch (error) {
        this.showError(error);
      } finally {
        this.loadingCars = false;
        this.loadingExtraCars = false
      }
    },
    async fetchBestOffers() {
      try {
        this.loadingBestOffers = true;

        const query = {}

        const request = await this.useJwt().ristic.bestOffers.list(query);
        const { data } = request.data;

        const [first] = data;
        this.$store.commit("ristic/SET_BEST_OFFER", first || null);
      } catch (error) {
        this.showError(error);
      } finally {
        this.loadingBestOffers = false;
      }
    },
    async getCar(id) {
      try {
        this.loadingCars = true;

        const response = await this.useJwt().ristic.cars.get(id);

        if (this.isLoggedIn) {
          // send request to log car.
          this.useJwt().ristic.carsViewedHistory.add(id);
        }

        const data = this.getValueFromSource(response, "data.data");
        this.$store.commit("ristic/SET_CURRENT_CAR", data || []);
        this.currentViewedCar = data
        return data;
      } catch (error) {
        this.showError(error);
      } finally {
        this.loadingCars = false;
      }
    },
    async fetchBookingLocations() {
      try {
        this.loadingBookingLocations = true;
        const response = await this.useJwt().ristic.miscellaneous.fetchBookingLocations();

        const data = this.getValueFromSource(response, "data.data");
        this.$store.commit("ristic/SET_BOOKING_LOCATIONS", data || []);
        return data;
      } catch (error) {
        this.showError(error);
      } finally {
        this.loadingBookingLocations = false;
      }
    },
    async verifyPayment(params) {
      try {
        this.verifyingPayment = true;
        const response = await this.useJwt().ristic.car_bookings.verifyPayment(params);

        const data = this.getValueFromSource(response, "data.data");

        data.paid && this.$store.commit("ristic/SET_AUTH_UI_STATE", {
          key: "bookingSuccess",
          value: true,
        });
        return data;
      } catch (error) {
        this.showError(error);
      } finally {
        this.verifyingPayment = false;
      }
    },
    async cancelPayment(txref) {
      try {
        this.cancellingPayment = true;
        this.useJwt().ristic.car_bookings.cancelPayment(txref);
      } catch (error) {
        this.showError(error);
      } finally {
        this.cancellingPayment = false;
      }
    },
    async fetchFavoriteCars() {
      try {
        this.loading = true;

        const request = await this.useJwt().ristic.favoriteCars.favoriteCarIds();
        const { data } = request.data;

        this.favorite_cars = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onAddFavorite(car_id) {
      try {
        this.loading = true;

        await this.useJwt().ristic.favoriteCars.add(car_id);
        await this.fetchFavoriteCars()
        await this.refetchCars()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            variant: 'success',
            icon: 'CheckCircleIcon',
            text: `Car added to favorites successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onRemoveFavorite(car_id) {
      try {
        this.loading = true;

        await this.useJwt().ristic.favoriteCars.remove(car_id);
        await this.fetchFavoriteCars()
        await this.refetchCars()
        this.$emit('refetchData')

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            variant: 'success',
            icon: 'CheckCircleIcon',
            text: `Car removed from favorites successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async fetchFeaturedCars(car_type_id) {
      try {
        this.loadingCars = true;
        let request

        if (car_type_id){
          request = await this.useJwt().ristic.featuredCars.listSimilar({ car_type_id, car_id: this.currentViewedCar._id });
        } else {
          request = await this.useJwt().ristic.featuredCars.list();
        }
        const { data } = request.data;
        
        this.featured_cars = data.map(this.makeCarDisplayData);
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loadingCars = false;
      }
    },
    refetchCars() {
      const { search } = this.$route.query
      this.fetchCars(search)
    },
    isFavoriteCar(car_id) {
      return this.favorite_cars.some(el => el.car_id === car_id);
    },
    makeCarDisplayData(car) {
      const car_pricing = this.getValueFromSource(car, "car_pricing")
      const default_location = Array.isArray(car_pricing) ? car_pricing.find(el => el.default_location) : { amount: 0 }
      return {
        description: this.getValueFromSource(car, "car_description"),
        image: this.getFirstCarImage(car),
        rate: default_location ? default_location.amount : 0,
        brand_title: this.getValueFromSource(car, "car_brand.title") || this.getValueFromSource(car, "car_brand_title"),
        make_title: this.getValueFromSource(car, "car_make.title") || this.getValueFromSource(car, "car_make_title"),
        car_model_year: this.getValueFromSource(car, "car_model_year"),
        ...car,
      };
    },
    sanitizeArrayFilters(filters) {
      return Object.keys(filters).reduce((acc, key) => {
        const value = filters[key];
        if (Array.isArray(value) && value.length > 0) {
          acc[key] = value.filter((item) => item !== undefined && item !== null);
        }
        return acc;
      }, {});
    },
    computeBookingDays(booking) {
      if (!booking || !booking.booking_start_date || !booking.booking_end_date) {
        return 0;
      }
      const startDate = new Date(booking.booking_start_date);
      const endDate = new Date(booking.booking_end_date);

      let fullDays = differenceInCalendarDays(endDate, startDate);

      const endTime = booking.booking_end_time;
      if (endTime) {
        const [endHour] = endTime.split(':');
        const parsedEndHour = parseInt(endHour, 10);
        if (parsedEndHour > this.openingHour) {
          fullDays += 1;
        }
      }
      return fullDays
    },
    computeTotalPayments(booking) {
      if (!booking) {
        return 0;
      }
      return this.getValueFromSource(booking, 'booking_payments', []).reduce((acc, el) => {
        return acc + el.amount;
      }, 0);
    },
    remainingAmount(booking) {
      if (!booking) {
        return 0;
      }
      const { total_amount } = booking || {};
      return total_amount - this.computeTotalPayments(booking);
    },
  },
};
