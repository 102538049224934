<!-- eslint-disable vue/no-v-html -->
<template>
  <b-overlay :show="loadingCars || loading">
    <section>
      <div class="mx-4">
        <swiper
          ref="swiperTop"
          class="swiper-gallery gallery-top"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide v-for="(data, index) in carImages" :key="index">
            <b-img :src="data.img" fluid />
          </swiper-slide>

          <div
            slot="button-next"
            class="swiper-button-next swiper-button-white"
          />
          <div
            slot="button-prev"
            class="swiper-button-prev swiper-button-white"
          />
        </swiper>

        <swiper
          ref="swiperThumbs"
          class="swiper gallery-thumbs"
          :options="swiperOptionThumbs"
        >
          <swiper-slide v-for="(data, index) in carImages" :key="index">
            <b-img :src="data.img" fluid />
            <!-- <car-image-display
              width="360"
              height="170"
              alt="Car"
              :src="getValueFromSource(data, 'img')"
              :hash="getValueFromSource(data, 'hash')"
            /> -->
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section>
      <b-row class="mx-4 my-3 mb-5">
        <b-col cols="12" md="9" class="p-0 mb-1">
          <h4 class="font-weight-bolder text-dark mb-1">
            {{ getValueFromSource(carFromStore, "car_model_year") }} {{ getValueFromSource(carFromStore, "brand_title") }} {{ getValueFromSource(carFromStore, "make_title") }}
          </h4>

          <div class="col-12 col-md-9 col-lg-9 p-0">
            <!-- [vue/no-v-text-v-html-on-component] -->
            <b-card-text v-html="carFromStore.car_description" />

            <div class="d-flex">
              <div class="mt-2 mr-3">
                <h5 class="text-dark font-weight-bold mb-1">
                  Car Specifications
                </h5>
  
                <b-col v-if="getValueFromSource(carFromStore, 'acceleration')" class="mt-1">
                  <b-card-text>
                    <div class="d-flex align-items-center round-img-wrapper">
                      <div class="round-img">
                        <b-img 
                          src="@/assets/images/icons/acceleration-icon.png" style="width: 30px; height: auto;"
                        /> 
                      </div>
                      {{ getValueFromSource(carFromStore, 'acceleration') }}sec
                    </div>
                  </b-card-text>
                </b-col>

                <b-col v-if="getValueFromSource(carFromStore, 'horse_power')" class="mt-1">
                  <b-card-text>
                    <div class="d-flex align-items-center round-img-wrapper">
                      <div class="round-img">
                        <b-img 
                          src="@/assets/images/icons/bhp-icon.png" style="width: 30px; height: auto;"
                        /> 
                      </div>
                      {{ getValueFromSource(carFromStore, 'horse_power') }}hp
                    </div>
                  </b-card-text>
                </b-col>

                <b-col class="mt-1">
                  <b-card-text>
                    <div class="d-flex align-items-center round-img-wrapper">
                      <div class="round-img">
                        <b-img 
                          src="@/assets/images/icons/mpg-icon.png" style="width: 30px; height: auto;"
                        /> 
                      </div>
                      {{ sentenceCase(getValueFromSource(carFromStore, 'fuel_type')) }}
                      
                      <span v-if="getValueFromSource(carFromStore, 'miles_per_gallon')">
                        , {{ getValueFromSource(carFromStore, 'miles_per_gallon') }} MPG
                      </span>
                    </div>
                  </b-card-text>
                </b-col>

                <b-col class="mt-1">
                  <b-card-text>
                    <div class="d-flex align-items-center round-img-wrapper">
                      <div class="round-img">
                        <b-img 
                          src="@/assets/images/icons/capacity-icon.png" style="width: 30px; height: auto;"
                        /> 
                      </div>
                      {{ getValueFromSource(carFromStore, 'capacity') }}people
                    </div>
                  </b-card-text>
                </b-col>

                <b-col class="mt-1">
                  <b-card-text>
                    <div class="d-flex align-items-center round-img-wrapper">
                      <div class="round-img">
                        <b-img 
                          src="@/assets/images/icons/transmission-icon.png" style="width: 30px; height: auto;"
                        /> 
                      </div>
                      {{ sentenceCase(getValueFromSource(carFromStore, 'transmission')) }}
                    </div>
                  </b-card-text>
                </b-col>
              </div>

              <div class="mt-2">
                <h5 class="text-dark font-weight-bold mb-1">Car Features</h5>
                <div style="max-height: 200px; overflow-y: auto">
                  <b-card-text
                    v-for="(car_feature, index) of carFromStore.car_features || []"
                    :key="index"
                  >
                    <span>
                      <feather-icon icon="CheckIcon" size="20" color="green" />
                    </span>
                    {{ car_feature.title }}
                  </b-card-text>
                </div>
              </div>
            </div>

            <div v-if="openingTime && closingTime" class="mt-5">
              <h5 class="text-dark font-weight-bold mb-1">
                Working Hours
              </h5>
              <div class="ml-1">
                {{ openingTime }} - {{ closingTime }}
              </div>
            </div>

            <div v-if="enableRefunds" class="mt-5">
              <h5 class="text-dark font-weight-bold mb-1">
                Cancellation Policy
              </h5>
              <div>
                You'll incur a cancellation penalty of {{ carRentalSettings.refund_penalty_percentage }}% if you cancel a Booking
                within {{ refundWindowText }} of pickup date.
              </div>
            </div>

            <div v-if="termsAndConditions" class="mt-5" v-html="termsAndConditions">

            </div>
          </div>
        </b-col>

        <b-col cols="12" md="3" class="p-0">
          <b-card>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <h4 class="text-dark font-weight-bold">
                <small class="text-muted">
                  Starting From
                </small> 
                 ${{ formatMoney(carFromStore.rate) }}/<small class="text-muted">day</small>
              </h4>

              <b-button variant="primary" size="md" @click="toggleRentSidebar">Rent</b-button>
            </div>

            <div class="">
              <div class="my-1">
                <h5 class="text-dark font-weight-bold">
                  Car Brand
                </h5>
                <b-card-text>
                  <span>
                    <b-avatar
                      size="32"
                      :src="getValueFromSource(carFromStore, 'car_brand.logo.path')"
                      :text="sentenceCase(avatarText(`${getValueFromSource(carFromStore, 'car_brand.title')}`))"
                      :variant="`light-primary`"
                    />
                  </span>
                  <span>
                    {{ getValueFromSource(carFromStore, 'car_brand.title') }}
                  </span>
                </b-card-text>
              </div>
  
              <div class="">
                <h5 class="text-dark font-weight-bold">Car Type</h5>
                <b-card-text>
                  {{ getValueFromSource(carFromStore, "car_type.title") }}
                </b-card-text>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <section>
      <div class="px-4">
        <custom-scroll-wrapper title="You might also like">
          <swiper-slide
            v-for="(car, i) in carsToDisplay"
            :key="i"
            class="rounded swiper-shadow"
          >
            <car-display-card 
              :car="car" 
              :is-favorite="isFavoriteCar(car._id)"
              @add-favorite="$car_id => onAddFavorite($car_id)"
              @remove-favorite="$car_id => onRemoveFavorite($car_id)"
            />
          </swiper-slide>
        </custom-scroll-wrapper>
      </div>
    </section>

    <div>
      <booking-sidebar
        :show.sync="showRentSidebar"
      >
      </booking-sidebar>
    </div>
  </b-overlay>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import {
  BImg,
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BCardText,
  BOverlay,
  BSidebar,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";

import "swiper/css/swiper.css";

import BookingSidebar from "@/@core/components/shared/BookingSidebar.vue";
import TimeRangeSelector from "@/@core/components/shared/TimeRangeSelector.vue";
import CustomScrollWrapper from "@/@core/components/shared/CustomScrollWrapper.vue";

import { get } from "lodash";

import CarImageDisplay from '@core/components/shared/CarImageDisplay.vue';
import CarDisplayCard from "@/@core/components/shared/CarDisplayCard.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import vSelect from "vue-select";
import CarsMixin from "@/@core/mixins/cars";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BOverlay,
    BSidebar,
    BFormGroup,
    BAvatar,

    vSelect,
    Swiper,
    SwiperSlide,
    BFormCheckbox,
    CarDisplayCard,
    BookingSidebar,
    TimeRangeSelector,
    BFormCheckboxGroup,

    CustomScrollWrapper,
    CarImageDisplay
  },
  mixins: [CarsMixin],
  data() {
    return {
      booking: {
        fuelCar: '',
        destinations: [],
        pickupLocation: 'airport',
        rentalTimeDuration: ["12:00", "12:00"],
        rentalDateDuration: [new Date(), new Date()],
      },
      favorite_cars: [],
      pickupLocations: [
        {
          label: 'Kotoka International Airport',
          value: 'airport'
        },
        {
          label: 'Arrange With Driver',
          value: 'arrange_with_driver'
        }
      ],
      swiperOptions: {
        loop: true,
        loopedSlides: 5,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 4,
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
      showRentSidebar: false,
      loading: false,
    };
  },
  computed: {
    carFromStore() {
      return this.makeCarDisplayData(this.$store.state.ristic.currentCar);
    },
    carsToDisplay() {
      return this.featured_cars.length ? this.featured_cars : this.carsAvailableForDisplay;
    },
    carImages() {
      if (this.carFromStore) {
        return [
          ...(this.carFromStore.car_images || []).map((carImage) => ({
            img: this.getValueFromSource(carImage, "image.path"),
            hash: this.getValueFromSource(carImage, "image.hash"),
          })),
        ];
      }
      return [];
    },
    carPricingListForAttachedLocations() {
      if (this.carFromStore && this.carFromStore.car_pricing) {
        return this.carFromStore.car_pricing.map(opt => ({
          _id: opt._id,
          rate: opt.amount,
          location: this.getValueFromSource(opt, 'location.title'),
        })) || []
      }

      return [];
    },
    bookingLocations() {
      return this.$store.state.ristic.bookingLocations;
    },
    defaultBookingLocation() {
      return this.bookingLocations.find((location) => location.is_default_location);
    },
    defaultLocationPriceId() {
      const default_price = (this.carFromStore.car_pricing || []).filter(((car_pricing) => car_pricing.location.is_default_location));
      return default_price.length ? default_price[0]._id : "";
    },
  },
  watch: {
    '$route.params.id': {
      handler(new_id) {
        this.getCar(new_id); 
      },
      immediate: false
    },
    showRentSidebar(val) {
      if (val) {
        if (this.booking && this.booking.destinations && this.booking.destinations.length === 0) {
          this.booking.destinations = [this.defaultLocationPriceId];
        }
      }
    },
    isLoggedIn: {
      handler(isLoggedIn){
        if (isLoggedIn){
          this.fetchFavoriteCars()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.fetchCars('', false);
    this.getCar(this.$route.params.id);

    this.processUIUpdates();

    if (this.isLoggedIn) {
      this.fetchFavoriteCars()
    }
  },
  methods: {
    async fetchFavoriteCars() {
      try {
        this.loading = true;

        const query = {
          search: this.currentUser._id,
        }

        const request = await this.useJwt().ristic.favoriteCars.list(query);
        const { data } = request.data;

        this.favorite_cars = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    isFavoriteCar(car_id) {
      return this.favorite_cars.some(el => el.car_id === car_id);
    },
    toggleRentSidebar() {
      if (this.currentUser && this.isLoggedIn){
        this.showRentSidebar = !this.showRentSidebar;
      } else {
        this.$store.commit("ristic/SET_AUTH_UI_STATE", { key: 'login', value: true });
        this.$store.commit("ristic/SET_RENTAL_AUTH_UI_STATE", true);
      }
    },
    processUIUpdates() {
      const { href } = window.location;
      if (href.includes('rent-now')) {
        this.toggleRentSidebar()
      }
    }
  },
};
</script>

<style scoped lang="scss">
  .round-img {
    background-color: #f0f0f0;
    border-radius: 50%;
    padding: .5rem;
    margin-right: .25rem;
  }

  .round-img-wrapper {
    color: #898384;
  }
</style>
