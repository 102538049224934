<template>
  <div class="navbar-container main-menu-content">
    <div class="d-flex align-items-center">
      <div 
        v-if="!getValueFromSource(generalAppSettings, 'system_message_settings.message')"
        class="mr-auto mb-0 mt-0 ml-4"
      >
        <div class="d-flex align-items-center">
          <b-button
            variant="outline-none"
            class="btn-icon mr-0"
          >
            <feather-icon icon="InstagramIcon" size="14" class="text-primary" />
          </b-button>

          <b-button
            variant="outline-none"
            class="btn-icon"
          >
            <feather-icon icon="FacebookIcon" size="14" class="text-primary" />
          </b-button>

          <b-button
            variant="outline-none"
            class="btn-icon mr-0"
          >
            <feather-icon icon="YoutubeIcon" size="14" class="text-primary" />
          </b-button>
        </div>
      </div>

      <div 
        v-if="getValueFromSource(generalAppSettings, 'system_message_settings.message')"
        class="w-100"  
      >
        <b-alert 
          v-if="getValueFromSource(generalAppSettings, 'system_message_settings.message')"
        
          show
          :variant="getValueFromSource(generalAppSettings, 'system_message_settings.message_variant')"
        >
        <div class="d-flex align-items-center">
          <div class="mr-auto mb-0 mt-0 ml-4">
            <div class="d-flex align-items-center">
              <b-button
                variant="outline-none"
                class="btn-icon mr-0"
              >
                <feather-icon icon="InstagramIcon" size="14" class="text-primary" />
              </b-button>

              <b-button
                variant="outline-none"
                class="btn-icon"
              >
                <feather-icon icon="FacebookIcon" size="14" class="text-primary" />
              </b-button>

              <b-button
                variant="outline-none"
                class="btn-icon mr-0"
              >
                <feather-icon icon="YoutubeIcon" size="14" class="text-primary" />
              </b-button>
            </div>
          </div>
  
          <div class="mr-auto">
            {{ getValueFromSource(generalAppSettings, 'system_message_settings.message') }}
          </div>
        </div>
        </b-alert>
      </div>
    </div>

    <div class="navbar-container main-menu-content d-flex justify-content-between align-items-end px-4">
      <img src="@/assets/images/logo/logo.png" width="120px" />
      
      <div>
        <horizontal-nav-menu-items :items="PublicMenuItems" class="" />
  
        <div>
          <booking-sidebar
            :show.sync="showRentSidebar"
          >
          </booking-sidebar>
        </div>
      </div>
      
      <b-nav v-if="isLoggedIn" id="auth-user" class="d-flex align-items-center" style="gap: 10px;">
        <b-nav-item-dropdown
          toggle-class="d-flex align-items-center justify-content-end"
          class=""
        >
          <template #button-content>
            <span class="text-black" style="color: black;">My rentals</span>
          </template>
          <b-dropdown-item :to="{ name: 'my-rentals' }">My rentals</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'history' }">History</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'favorites' }">Favorites</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown
          toggle-class="d-flex align-items-center justify-content-end"
          class=" user-dropdown"
          no-caret
        >
          <template #button-content>
            <div class="d-flex align-items-center" style="gap: 11px; margin-left: 5px;">
              <feather-icon icon="MenuIcon" size="24" color="black" />
              <b-avatar
                size="40"
                :src="userAvatar"
                variant="info"
              >
              </b-avatar>
            </div>
            <div v-if="!isVerified" v-b-tooltip.hover.topright title="Email verification pending" style="color: orange;">
              <feather-icon
                icon="InfoIcon"
                size="20"
                color="danger"
                class="ml-1"
              />
            </div>
          </template>
          <b-dropdown-item :to="{ name: 'personal-info' }">Personal Info</b-dropdown-item>
          <b-dropdown-item @click="onAddNew">Report a problem</b-dropdown-item>
          <b-dropdown-item @click="onContactSupport">Contact Support</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'terms' }">Terms & Conditions</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'privacy' }">Privacy Policy</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="logout">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-nav>
  
      <div v-else class="d-flex">
        <b-button variant="outline-primary" class="mr-2" @click="loginHandler">Login</b-button>
        <b-button variant="primary" @click="signUpHandler">Sign up</b-button>
      </div>
    
      <app-side-bar id="groot" :visible="authUIState.register" :show-logo="true">
        <auth-sign-up />
      </app-side-bar>
  
      <app-side-bar id="login-sidebar" :visible="authUIState.login">
        <auth-sign-in 
          @continueBrowse="showRentalSidebar"
        />
      </app-side-bar>
  
      <app-side-bar id="forgot-password-sidebar" :visible="authUIState.forgotPassword">
        <auth-forgot-password />
      </app-side-bar>

      <app-side-bar id="password-reset-sidebar" :visible="authUIState.passwordReset">
        <auth-reset-password />
      </app-side-bar>
    </div>

    <add-reported-problem-modal
      :is-add-or-update-active.sync="isAddOrUpdateActive"
    />
  </div>
</template>

<script>
import { BAlert, BNavItemDropdown, BLink, BButton, BSidebar, BDropdown, BNav, BDropdownItem, BDropdownDivider, BAvatar, BBadge, VBTooltip } from 'bootstrap-vue'

import PublicMenuItems from "@/navigation/public";
import AppSideBar from '@/@core/components/shared/AppSideBar.vue';
import AuthSignIn from '@/pages/user/auth/auth-sidebar/auth-signin.vue';
import AuthSignUp from '@/pages/user/auth/auth-sidebar/auth-signup.vue';
import BookingSidebar from "@/@core/components/shared/BookingSidebar.vue";
import AuthResetPassword from '@/pages/user/auth/auth-sidebar/auth-reset-password.vue';
import AuthForgotPassword from '@/pages/user/auth/auth-sidebar/auth-forgot-password.vue';
import AddReportedProblemModal from "@/@core/components/shared/AddReportedProblemModal.vue";
import UserDropdown from '@/@core/layouts/components/app-navbar/components/UserDropdown.vue';
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    UserDropdown,
    BNavItemDropdown,
    BAvatar,
    AuthForgotPassword,
    AuthResetPassword,
    AuthSignIn,
    AppSideBar,
    BLink,
    BButton,
    BSidebar,
    AuthSignUp,
    HorizontalNavMenuItems,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BookingSidebar,
    BAlert,
    BNav,
    BBadge,
    AddReportedProblemModal
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      showAuthSidebar: false,
      showSignIn: false,
      showSignUp: false,
      showRentSidebar: false,
      isAddOrUpdateActive: false,
    }
  },
  computed: {
    navigationItems() {
      return this.PublicMenuItems
    },
    authMode() {
      const { query } = this.$route
      return query['auth-mode']
    },
    isInAuthMode() {
      return ['login', 'signup'].includes(this.authMode)
    },
    authUIState() {
      return this.$store.state.ristic.authUIState
    },
    authRentalUIState() {
      return this.$store.state.ristic.authRentalUIState
    },
    isVerified() {
      return this.getValueFromSource(this.currentUser, 'meta.email_verified', false)
    },
  },
  setup() {
    return {
      PublicMenuItems,
    }
  },
  methods: {
    loginHandler() {
      this.$store.commit("ristic/SET_AUTH_UI_STATE", { key: 'login', value: true });
    },
    signUpHandler() {
      // this.$router.replace({ query: { 'auth-mode': 'register' } }).catch(() => {})
      this.$store.commit("ristic/SET_AUTH_UI_STATE", { key: 'register', value: true });
    },
    leaveAuthMode() {
      this.$router.push({ query: { 'auth-mode': null } }).catch((error) => {
        console.log("error", error)
      })
    },
    showRentalSidebar() {
      // Show sidebar if user was on rental page
      if (this.authRentalUIState) {
        this.$store.commit("ristic/RESET_RENTAL_AUTH_UI_STATE");
        this.showRentSidebar = !this.showRentSidebar
      }
    },
    onAddNew(){
      this.isAddOrUpdateActive = true;
    },
    onContactSupport(){
      if (window.Tawk_API) {
        window.Tawk_API.maximize();
      } else {
        console.error("Tawk_API is not working.");
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
@import '@core/scss/vue/pages/page-public.scss';
@import '@core/scss/vue/pages/page-auth.scss';

.user-dropdown {
  border: 1px solid #898384;
  border-radius: 999px;
  /* padding: 10px; */
}

#auth-user ul.dropdown-menu.show {
  position: absolute;
  transform: translate3d(0px, 24px, 0px);
  top: 50px !important;
  left: 0px;
  will-change: transform;
}


</style>
