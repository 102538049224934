
export default {
  namespaced: true,
  state: {
    cars: [],
    carTypes: [],
    carBrands: [],
    carFeatures: [],
    minPrice: 0,
    maxPrice: 0,
    minYear: 0,
    maxYear: 0,
    bestOffer: null,
    currentCar: null,
    bookingLocations: [],
    authRentalUIState: false,
    authUIState: {
      login: false,
      register: false,
      forgotPassword: false,
      passwordReset: false,
      bookingSuccess: false,
    },
    filters: {
      car_types: [],
      car_brands: [],
      car_features: [],

      year_range: [0, 0],
      price_range: [0, 0],
    },
    sort_cars_by: 'created_desc'
  },
  getters: {
    cars: state => state.cars,
    filters: state => state.filters,
    carTypes: state => state.carTypes,
    carBrands: state => state.carBrands,
    carFeatures: state => state.carFeatures,
    authUIState: state => state.authUIState,
    sort_cars_by: state => state.sort_cars_by,
    authRentalUIState: state => state.authRentalUIState,
  },
  mutations: {
    SET_BRANDS(state, val) {
      state.carBrands = val
    },
    SET_CAR_TYPES(state, val) {
      state.carTypes = val
    },
    SET_CAR_FEATURES(state, val) {
      state.carFeatures = val
    },
    SET_MIN_PRICE(state, val) {
      state.minPrice = val;
    },
    SET_MAX_PRICE(state, val) {
      state.maxPrice = val;
    },
    SET_MIN_YEAR(state, val) {
      state.minYear = val;
      state.filters.year_range[0] = val;
    },
    SET_MAX_YEAR(state, val) {
      state.maxYear = val;
      state.filters.year_range[1] = val;
    },
    SET_CARS(state, val) {
      state.cars = val
    },
    SET_BEST_OFFER(state, val) {
      state.bestOffer = val
    },
    SET_CURRENT_CAR(state, val) {
      state.currentCar = val
    },
    SET_BOOKING_LOCATIONS(state, val) {
      state.bookingLocations = val
    },
    RESET_BOOKING_LOCATIONS(state) {
      state.bookingLocations = []
    },
    SET_AUTH_UI_STATE(state, { key, value }) {
      state.authUIState = {
        login: false,
        register: false,
        forgotPassword: false,
        passwordReset: false,
        bookingSuccess: false,
        [key]: value
      }
    },
    RESET_AUTH_UI_STATE(state) {
      state.authUIState = {
        login: false,
        register: false,
        passwordReset: false,
        forgotPassword: false,
        bookingSuccess: false,
      }
    },
    SET_RENTAL_AUTH_UI_STATE(state, value) {
      state.authRentalUIState = value
    },
    RESET_RENTAL_AUTH_UI_STATE(state) {
      state.authRentalUIState = false
    },
    UPDATE_FILTER(state, { key, value }) {
      state.filters[key] = value
    },
    SET_FILTER(state, newFilter) {
      state.filters = newFilter
    },
    SET_SORT_CAR_BY(state, value) {
      state.sort_cars_by = value
    }
  },
  actions: {},
}
