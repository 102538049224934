<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isHorizontalMobileMenuCollapsed || (isHorizontalMobileMenuCollapsed && isMouseHovered) },
      skin === 'dark' ? 'menu-dark' : 'menu-light'
    ]"
  >
    <!-- main menu header-->
    <div class="w-100 px-1 py-1">
      <div class="d-flex align-items-center justify-content-between">
        <img src="@/assets/images/logo/logo.png" width="120px" />
        <b-button class="d-block" variant="outline-*" @click="$emit('toggle-horizontal-mobile-menu-active')">
          <feather-icon icon="XIcon" size="30" />
        </b-button>
      </div>
    </div>
    <!-- / main menu header-->

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="mobile-menu-content scroll-area horizontal-mobile-menu-content position-relative"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <div class="scroll-area">
        <horizontal-mobile-nav-menu-items
          :items="getMenuItems"
          class="mobile-menus"
          @toggle-horizontal-mobile-menu-active="$emit('toggle-horizontal-mobile-menu-active')"
        />

        <hr class="mx-4" />

        <horizontal-mobile-nav-menu-items
          :items="otherMenuItems"
          class="mobile-menus"
          @toggle-horizontal-mobile-menu-active="$emit('toggle-horizontal-mobile-menu-active')"
        />
      </div>

      <div v-if="!isLoggedIn" class="w-100 fixed-bottom px-2 bg-primary" style="padding-bottom: 20px;">
        <b-button class="w-100 mb-1 bg-white text-dark" variant="outline-light" @click="loginHandler">Login</b-button>
        <b-button class="w-100 d-block bg-primary" variant="outline-light" @click="signUpHandler">Sign up</b-button>
      </div>

      <div v-else class="w-100 fixed-bottom px-2 bg-primary" style="padding-bottom: 20px;">
        <b-button class="w-100 d-block bg-primary" variant="outline-light" @click="logoutHandler">Logout</b-button>
      </div>

      <hr v-if="isLoggedIn" class="mx-4" />

      <div v-if="isLoggedIn" class="w-100 px-2 bg-primary" style="padding-top: 10px; border: none;">
        <b-button class="w-100 d-block bg-primary text-large" variant="outline-light" style="border: none; font-size: 16px; font-weight: bolder;" @click="onAddNewProblem">Report a problem</b-button>
      </div>

      <div v-if="isLoggedIn" class="w-100 px-2 bg-primary" style="padding-top: 10px; border: none;">
        <b-button class="w-100 d-block bg-primary text-large" variant="outline-light" style="border: none; font-size: 16px; font-weight: bolder;" @click="showChat">Contact Support</b-button>
      </div>

      <div class="w-100 px-2 bg-primary text-center" style="padding-top: 10px; border: none;">
        <div class="d-flex w-100 justify-content-center align-items-center">
          <b-button
            variant="outline-none"
            class=" bg-primary text-large"
          >
            <feather-icon icon="InstagramIcon" size="20" class="text-white" />
          </b-button>

          <b-button
            variant="outline-none"
            class=" bg-primary text-large"
          >
            <feather-icon icon="FacebookIcon" size="20" class="text-white" />
          </b-button>

          <b-button
            variant="outline-none"
            class=" bg-primary text-large"
          >
            <feather-icon icon="YoutubeIcon" size="20" class="text-white" />
          </b-button>
        </div>
      </div>

      <div>
        <app-side-bar id="groot" :visible="authUIState.register" :show-logo="true">
          <auth-sign-up />
        </app-side-bar>
    
        <app-side-bar id="login-sidebar" :visible="authUIState.login">
          <auth-sign-in 
            @closeMobile="$emit('toggle-horizontal-mobile-menu-active')"
          />
        </app-side-bar>
    
        <app-side-bar id="forgot-password-sidebar" :visible="authUIState.forgotPassword">
          <auth-forgot-password 
            @closeMobile="$emit('toggle-horizontal-mobile-menu-active')"
          />
        </app-side-bar>
      </div>
    </vue-perfect-scrollbar>
    <!-- /main menu content-->

    <add-reported-problem-modal
      :is-add-or-update-active.sync="isAddOrUpdateActive"
    />
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig';
import { BLink, BImg, BButton } from 'bootstrap-vue';
import { provide, computed, ref } from '@vue/composition-api';

// import navMenuItems from '@/navigation/vertical';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import UserMenuItems from "@/navigation/user";
import PublicMenuItems from "@/navigation/public";
import AppSideBar from '@/@core/components/shared/AppSideBar.vue';
import AuthSignIn from '@/pages/user/auth/auth-sidebar/auth-signin.vue';
import AuthSignUp from '@/pages/user/auth/auth-sidebar/auth-signup.vue';
import AuthForgotPassword from '@/pages/user/auth/auth-sidebar/auth-forgot-password.vue';

import useAppConfig from '@core/app-config/useAppConfig';

import AddReportedProblemModal from "@/@core/components/shared/AddReportedProblemModal.vue";
import useHorizontalMobileNavMenu from './useHorizontalMobileNavMenu';
import HorizontalMobileNavMenuItems from './components/horizontal-mobile-nav-menu-items/HorizontalMobileNavMenuItems.vue';

export default {
  components: {
    VuePerfectScrollbar,
    HorizontalMobileNavMenuItems,
    AddReportedProblemModal,
    BImg,
    BLink,
    AppSideBar,
    AuthSignUp,
    AuthSignIn,
    AuthForgotPassword,
    BButton
  },
  props: {
    toggleHorizontalMobileMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isAddOrUpdateActive: false
    }
  },
  computed: {
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    authUIState() {
      return this.$store.state.ristic.authUIState
    },
    getMenuItems(){
      const defaultMenus = PublicMenuItems;
      
      if (this.userType === "user") {
        defaultMenus.push(...UserMenuItems)
        return defaultMenus
      }

      return defaultMenus
    },
    otherMenuItems() {
      const menu_items = [
        {
          title: 'Terms & Conditions',
          action: "read",
          route: "terms",
          resource: "AppRoot"
        },
        {
          title: 'Privacy Policy',
          action: "read",
          route: "privacy",
          resource: "AppRoot"
        },
      ]
      if (this.isLoggedIn){
        menu_items.unshift(
          {
            title: 'Personal Info',
            action: "read",
            route: "personal-info",
            resource: "AppRoot"
          },
          {
            title: 'My Rentals',
            action: "read",
            route: "my-rentals",
            resource: "AppRoot"
          },
          {
            title: 'History',
            action: "read",
            route: "history",
            resource: "AppRoot"
          },
          {
            title: 'Favorites',
            action: "read",
            route: "favorites",
            resource: "AppRoot"
          },
        ) 
      }
      return menu_items
    }
  },
  setup(props) {
    const {
      isMouseHovered,
      toggleCollapsed,
      updateMouseHovered,
      collapseTogglerIcon,
      isHorizontalMobileMenuCollapsed,
    } = useHorizontalMobileNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useHorizontalMobileNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage, appMobileModeLogoImage } = $themeConfig.app

    return {
      navMenuItems: [],
      perfectScrollbarSettings,
      isHorizontalMobileMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appMobileModeLogoImage
    }
  },
  methods: {
    loginHandler() {
      this.$store.commit("ristic/SET_AUTH_UI_STATE", { key: 'login', value: true });
    },
    logoutHandler() {
      this.logout()
      this.$emit('toggle-horizontal-mobile-menu-active')
    },
    signUpHandler() {
      // this.$router.replace({ query: { 'auth-mode': 'register' } }).catch(() => {})
      this.$store.commit("ristic/SET_AUTH_UI_STATE", { key: 'register', value: true });
    },
    showChat(){
      if (window.Tawk_API) {
        window.Tawk_API.maximize();
      } else {
        console.error("Tawk_API is not working.");
      }
    },
    onAddNewProblem(){
      this.isAddOrUpdateActive = true;
    },
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
