export default class CarViewHistoryJwtServices {
  axiosIns = null;

  jwtConfig = null;

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(params) {
    return this.axiosIns.get("cars/history", { params });
  }

  add(id) {
    return this.axiosIns.put(`cars/${id}/history`);
  }

  remove(id) {
    return this.axiosIns.delete(`cars/${id}/history`);
  }
}
