<!-- eslint-disable vue/no-v-html -->
<template>
  <b-link :to="{ name: 'rental-details', params: { id: car._id } }">
  <b-card no-body class="overflow-hidden d-flex border-primary" style="max-height: 170px;">
    <b-row no-gutters>
      <b-col md="4" style="background-color: red;">
        <car-image-display
          width="360"
          height="360"
          alt="Car"
          :src="getValueFromSource(car, 'car_images[0].image.path')"
          :hash="getValueFromSource(car, 'car_images[0].image.hash')"
          image-style="border-radius: 0px; max-height: 210px; height: 100%;"
        />
      </b-col>
      <b-col md="8">            
        <div class="p-1">
          <div class="d-flex align-items-center justify-content-between w-full">
            <h4 v-b-tooltip.hover class="text-dark ellipsis ellipsis-2" variant="text" :title="car.make_title">
              {{ car.car_model_year }} {{ car.brand_title }} {{ car.make_title }}
            </h4>
            <h3 class="text-primary">
              <!-- <small class="text-muted">From</small>  -->
              ${{ formatMoney(car.rate) }}/<small class="text-muted">day</small>
            </h3>
          </div>

          <b-card-text class="text-muted ellipsis-2 mt-1 mb-1 text-12">
            {{ extractTextContentFromHTML(car.description) }}
          </b-card-text>
          
          <div class="footer-div mb-0">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <b-badge class="mr-1" variant="light-dark">
                  {{ startCase(getValueFromSource(car, 'fuel_type')) }}
                </b-badge>
                <b-badge class="mr-1" variant="light-dark">
                  {{ getValueFromSource(car, 'capacity') }} seater
                </b-badge>

                <b-badge class="mr-1" variant="light-dark">
                  {{ sentenceCase(getValueFromSource(car, 'transmission')) }}
                </b-badge>
                
                <!-- <b-badge
                  v-for="(car_feature, index) of (car.car_features || []).slice(0,3)"
                  :key="index"
                  variant="light-dark"
                  class="mr-1"
                >
                  {{ car_feature.title }}
                </b-badge> -->
              </div>

              <b-button :to="{ name: 'rental-details', params: { id: car._id } }" variant="primary" size="sm">Interested</b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</b-link>
</template>

<script>
import {
  BCol,
  BCard,
  BCardText,
  BLink,
  BButton,
  VBTooltip,
  BImg,
  BRow,
  BBadge,
  BCardBody,
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { startCase } from "lodash";
import CarImageDisplay from "./CarImageDisplay.vue";

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BLink,
    BCard,
    BBadge,
    BButton,
    BCardBody,
    BCardText,

    CarImageDisplay,
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    car: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    isFavorite: {
      required: false,
      type: Boolean,
    },
    md: {
      type: Number,
      default: 2,
    },
    containerClass: {
      type: String,
      default: "pl-0",
    },
  },
  methods: {
    startCase
  }
};
</script>

<style lang="scss" scoped>
.like-icon {
  padding: 5px;
  border-radius: 50%;
}

.like-icon-wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1 !important;
  justify-content: center;
}

.favorite-icon {
  background-color: #a1cb3a;
}

.footer-div {
  margin-top: auto;
}
</style>
