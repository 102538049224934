<template>
  <b-card class="">
    <b-input-group class="input-group-merge d-none d-md-flex pt-2">
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" size="20" />
      </b-input-group-prepend>

      <b-form-input 
        v-model="search"
        placeholder="Search for car make" 
        size="lg" 
        @input="onSearch" 
      />
    </b-input-group>

    <b-row class="mx-1 mb-2 mt-3">
      <b-col v-for="(brand, i) in carBrands" :key="i" cols="4" md="6" class="m-0 p-0 mb-1">
        <b-button class="rounded-circle p-0" :class="{ 'selected': isSelectedBrand(brand) }" size="md" style="background-color: #F0F0F0 !important; height: 44px; width: 44px;" variant="light" @click="selectCarBrand(brand)">
          <b-img :src="getValueFromSource(brand, 'logo.path')" alt="Brand Image" class="rounded-0" style="width: 30px;" />
        </b-button>

        <b-card-text :class="[{ 'selected-brand-name': isSelectedBrand(brand) }, 'text-12']">{{ brand.title }}</b-card-text>
      </b-col>
    </b-row>

    <div v-if="maxPrice > minPrice" class="mb-3">
      <h5 class="font-weight-bold text-dark mb-2">
        Price range
      </h5>

      <custom-range-slider 
        v-model="filters.price_range"
        :min="minPrice"
        :max="maxPrice"
        prepend="$"
      />
    </div>

    <div v-if="maxYear > minYear" class="mb-3">
      <h5 class="font-weight-bold text-dark mb-2">
        Year range
      </h5>

      <custom-range-slider
        v-model="filters.year_range"
        :min="minYear"
        :max="maxYear"
        prepend=""
      />
    </div>

    <div class="mb-3">
      <h5 class="font-weight-bold text-dark">
        Car Type
      </h5>

      <b-form-group style="max-height: 200px; overflow-y: auto;">
        <b-form-checkbox-group
          v-model="filters.car_types"
          name="car-types"
          class="demo-inline-spacing"
        >
          <b-row>
            <b-col v-for="(carType, i) in carTypes" :key="i" cols="6" md="12" class="mb-1">
              <b-form-checkbox :value="carType._id" class="text-dark">
                <span class="text-dark text-12">
                  {{ carType.title }} <span class="text-muted">({{ carType.cars_count }})</span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="pb-5">
      <h5 class="font-weight-bold text-dark">
        Car Features
      </h5>

      <b-form-group style="max-height: 200px; overflow-y: auto;">
        <b-form-checkbox-group
          v-model="filters.car_features"
          name="car-features"
          class="demo-inline-spacing"
        >
          <b-row>
            <b-col v-for="(carFeature) in carFeatures" :key="carFeature._id" cols="6" md="12" class="mb-1">
              <b-form-checkbox :value="carFeature._id" class="text-dark">
                <span class="text-dark text-12">
                  {{ carFeature.title }}
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-checkbox-group>
      </b-form-group>
    </div>
  </b-card>
</template>

<script>
  import {
    BRow,
    BCol,
    BImg,
    BLink,
    BCard,
    BButton,
    BOverlay,
    BCardText,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
  } from 'bootstrap-vue';

  import CarsMixin from "@/@core/mixins/cars";

  import { startCase } from 'lodash';

  import CustomRangeSlider from '@/@core/components/shared/CustomRangeSlider.vue';

  export default {
    components: {
      BRow,
      BCol,
      BImg,
      BLink,
      BCard,
      BButton,
      BOverlay,
      BCardText,
      BFormInput,
      BInputGroup,
      BFormGroup,
      BFormCheckbox,
      BFormCheckboxGroup,
      BInputGroupPrepend,

      CustomRangeSlider
    },
    mixins: [CarsMixin],
    data() {
      return {
        search: "",
        debouncedFetchCars: () => {},
      }
    },
    computed: {
      filters: {
        get() {
          return this.$store.state.ristic.filters;
        },
        set(value) {
          this.$store.commit("ristic/SET_FILTER", value);
        }
      }
    },
    async created() {
      await this.fetchRisticFilters()
      
      const newFilter = {}

      const { car_features, car_types, car_brands, year_range } = this.$route.query

      if (!Array.isArray(car_features)) {
        if (car_features) {
          newFilter.car_features = [car_features]
        }
      } else {
        newFilter.car_features = car_features
      }

      if (!Array.isArray(car_types)) {
        if (car_types) {
          newFilter.car_types = [car_types] 
        }
      } else {
        newFilter.car_types = car_types
      }

      if (!Array.isArray(car_brands)) {
        if (car_brands) {
          newFilter.car_brands = [car_brands]
        }
      } else {
        newFilter.car_brands = car_brands
      } 

      const filtered_year_range = Array.isArray(year_range) ? year_range.map(str => parseFloat(str)) : [this.minYear, this.maxYear];

      const updatedFilter = {
        ...this.filters,
        ...newFilter,
        year_range: filtered_year_range,
      }
      
      this.filters = updatedFilter
    },
    methods: {
      startCase,
      onSearch() {
        this.$emit("searchCar", this.search)
      },
      isSelectedBrand(brand) {
        if (this.filters && (this.filters.car_brands || []).includes(brand._id)) {
          return true
        }
        return false
      },
      selectCarBrand(item) {
        if (!this.filters.car_brands) {
          this.filters = {
            ...this.filters,
            car_brands: [item._id]
          }
          return;
        }
        const index = this.filters.car_brands.indexOf(item._id);
        if (index > -1) {
          this.filters.car_brands.splice(index, 1);
        } else {
          this.filters.car_brands.push(item._id);
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
.selected {
  /* border-color: rgb(220.5, 220.5, 220.5) !important; */
  border-color: rgb(161 203 59) !important;
  border-width: 2px;
}

.selected-brand-name {
  color: rgb(161 203 59) !important;
}
</style>
