export default class FeaturedCarsJwtServices {
  axiosIns = null;

  jwtConfig = null;

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list() {
    return this.axiosIns.get("featured-cars", {});
  }

  listSimilar(params) {
    return this.axiosIns.get("featured-cars/similar", { params });
  }
}
