<template>
  <div style="position: relative;" :style="[{ paddingBottom: computedRatio }, cssVars]">
    <transition-group mode="in-out" name="fade" style="height: 100%; width: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0;">
      <blur-hash-canvas 
        v-show="!imageLoaded" 
        key="canvas" 
        :hash="hash" 
        :punch="punch" 
        :width="width" 
        :height="height" 
        style="height: 100%; width: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0; border-radius: 8px 8px 0px 0px;"
      />

      <b-img 
        v-show="imageLoaded" 
        key="image" 
        :src="src" 
        :srcset="srcset" 
        style="height: 100%; width: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0; object-fit: cover; border-radius: 8px 8px 0px 0px;"
        :style="imageStyle"
        v-bind="$attrs" 
        @load="onLoaded" 
      />
    </transition-group>
  </div>
</template>

<script>
  import { BImg } from 'bootstrap-vue';
  import { BlurHashCanvas } from "vue-blurhash";

  export default {
    components: {
      BImg,
      BlurHashCanvas
    },
    props: {
      width: {
        type: [Number, String],
        required: true,
      },
      height: {
        type: [Number, String],
        required: true,
      },
      hash: {
        type: String,
        required: true,
      },
      src: {
        type: String,
        required: true,
      },
      srcset: {
        type: [String, null],
        default: null
      },
      imageStyle: {
        type: String,
        default: null
      },
      punch: {
        type: Number,
        default: 1
      },
      transitionDuration: {
        type: Number,
        default: 500
      }
    },
    data() {
      return {
        imageLoaded: false,
      }
    },
    computed: {
      computedRatio() {
        return this.percentRatio(this.parseNumber(this.height), this.parseNumber(this.width))
      },
      cssVars() {
        return {
          '--transition-duration': this.transitionDuration > 0 ? `${this.transitionDuration / 1000}s` : 'none'
        }
      }
    },
    methods: {
      onLoaded() {
        this.imageLoaded = true
      },
      parseNumber(number) {
        return typeof number === 'number' ? number : parseInt(number, 10)
      },
      percentRatio(height, width) {
        return `${((Math.round(height) / Math.round(width)) * 100).toFixed(2)}%`
      }
    }
  }
</script>

<style lang="scss" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity var(--transition-duration);
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>
