<template>
  <user-pages-wrapper title="Personal Information">
    <div class="mt-1">
      <validation-observer ref="newUserForm">
        <b-form ref="form" class="mb-5" @submit.prevent="onUpdateUser">
          <b-row class="match-height">
            <b-col cols="12" md="4">
              <b-card>
                <b-media class="mt-5 pt-2 text-center">
                  <b-avatar
                    ref="previewEl"
                    class="text-center"
                    :src="avatarPath"
                    :text="avatarText(`${currentUser.first_name} ${currentUser.last_name}`)"
                    :variant="`light-${resolveStatusVariant(currentUser.status)}`"
                    size="10rem"
                  >
                    <template #badge>
                      <b-button variant="primary" class="btn-icon rounded-circle" @click="$refs.avatarElement.$el.childNodes[0].click()">
                        <feather-icon icon="EditIcon" />
                      </b-button>
                    </template>
                  </b-avatar>
                  
                  <b-form-file
                    v-show="false"
                    ref="avatarElement"
                    accept="image/*"
                    placeholder=""
                    no-drop
                    @input="onImageRenderer"
                  />

                  <h3 class="mt-3 text-dark">{{ sentenceCase(userData.full_name) }}</h3>
                </b-media>

                <div v-if="!isVerified" class="d-flex align-items-center">
                  <!-- <div class="ml-2">
                    Email Verification
                  </div> -->

                  <div v-if="!isVerified" class="ml-2">
                    <b-alert 
                      class="text-center p-1"
                      show
                      variant="warning"
                    >
                      Email Verification Pending
                    </b-alert>
                  </div>

                  <div class="ml-2 mb-1">
                    <b-link @click="resendAccountConfirmationEmail">
                      Click here to send confirmation link
                    </b-link>
                  </div>
                </div>
              </b-card>
            </b-col>

            <b-col cols="12" md="4">
              <b-card>
                <b-row>
                  <b-col class="mt-1">
                    <validation-provider #default="validationContext" name="First Name" rules="required">
                      <b-form-group label-for="first_name">
                        <template #label>
                          <span class="ml-1">First Name</span>
                        </template>
    
                        <b-row>
                          <b-col cols="10">
                            <b-form-input
                              id="first_name"
                              v-model="userData.first_name"
                              class="border-0"
                              placeholder="First Name"
                              :state="getValidationState(validationContext)"
                            />
                            <hr />
                          </b-col>
                          <b-col cols="2" class="mt-1">
                            <b-link @click="onUpdateUser">
                              Edit
                            </b-link>
                          </b-col>
                        </b-row>
    
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <validation-provider #default="validationContext" name="Last Name" rules="required">
                      <b-form-group label-for="last_name">
                        <template #label>
                          <span class="ml-1">Last Name</span>
                        </template>
    
                        <b-row>
                          <b-col cols="10">
                            <b-form-input
                              id="last_name"
                              v-model="userData.last_name"
                              class="border-0"
                              placeholder="Last Name"
                              :state="getValidationState(validationContext)"
                            />
                            <hr />
                          </b-col>
                          <b-col cols="2" class="mt-1">
                            <b-link @click="onUpdateUser">
                              Edit
                            </b-link>
                          </b-col>
                        </b-row>
    
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <validation-provider #default="validationContext" name="Phone" rules="required|length:10">
                      <b-form-group label-for="phone">
                        <template #label>
                          <span class="ml-1">Phone</span>
                        </template>
    
                        <b-row>
                          <b-col cols="10">
                            <b-form-input
                              id="phone"
                              v-model="userData.phone"
                              class="border-0"
                              type="number"
                              :state="getValidationState(validationContext)"
                            />
                            <hr />
                          </b-col>
                          <b-col cols="2" class="mt-1">
                            <b-link @click="onUpdateUser">
                              Edit
                            </b-link>
                          </b-col>
                        </b-row>
    
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <validation-provider #default="validationContext" name="Email" rules="required|email">
                      <b-form-group label-for="email">
                        <template #label>
                          <span class="ml-1">Email</span>
                        </template>
    
                        <b-row>
                          <b-col cols="10">
                            <b-form-input
                              id="email"
                              v-model="userData.email"
                              class="border-0"
                              :state="getValidationState(validationContext)"
                            />
                            <hr />
                          </b-col>
                          <b-col cols="2" class="mt-1">
                            <b-link @click="onUpdateUser">
                              Edit
                            </b-link>
                          </b-col>
                        </b-row>
    
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col cols="12" md="4">
              <b-card>
                <h3 class="mt-1 text-center">Help & Support</h3>
                <b-row>
                  <b-col class="mt-1">
                    <b-row>
                      <b-col class="ml-1 mr-1">
                        <span>
                          <feather-icon icon="PhoneIcon" />
                          <span class="align-middle ml-50">
                            <b-link @click="showChat">
                              Contact Support
                            </b-link> 
                          </span>
                        </span>
                        <hr />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="mt-1">
                    <b-row>
                      <b-col class="ml-1 mr-1">
                        <span>
                          <feather-icon icon="AlertTriangleIcon" />
                          <span class="align-middle ml-50">
                            <b-link @click="onAddNew">
                              Report a problem
                            </b-link> 
                          </span>
                        </span>
                        <hr />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="mt-1">
                    <b-row>
                      <b-col class="ml-1 mr-1">
                        <span>
                          <feather-icon icon="FileTextIcon" />
                          <span class="align-middle ml-50">
                            <b-link @click="onTerms">
                              Terms & Conditions
                            </b-link> 
                          </span>
                        </span>
                        <hr />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="mt-1">
                    <b-row>
                      <b-col class="ml-1 mr-1">
                        <span>
                          <feather-icon icon="FileIcon" />
                          <span class="align-middle ml-50">
                            <b-link @click="onPrivacy">
                              Privacy Policy
                            </b-link> 
                          </span>
                        </span>
                        <hr />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <add-reported-problem-modal
        :is-add-or-update-active.sync="isAddOrUpdateActive"
      />
    </div>
  </user-pages-wrapper>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BLink,
  BOverlay,
  BMedia,
  BAvatar,
  BAlert,
  BFormFile,
  BFormGroup,
  BFormInput,
  BCardText,
  BJumbotron,
  BCard,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue';

import { cloneDeep, get } from "lodash";
import { MUTATE_USER_DATA } from "@/store/config/mutation-keys";
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import UserPagesWrapper from "@/@core/layouts/components/UserPagesWrapper.vue";
import AddReportedProblemModal from "@/@core/components/shared/AddReportedProblemModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    UserPagesWrapper,
    BRow,
    BCol,
    BImg,
    BLink,
    BForm,
    BAlert,
    BCard,
    BMedia,  
    BAvatar,
    BFormFile,
    BOverlay,
    BCardText,
    BButton,
    BJumbotron,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    AddReportedProblemModal,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      avatarPath: '',
      userData: {},
      updatedImage: null,
      isAddOrUpdateActive: false,
    }
  },
  computed: {
    isVerified() {
      return this.getValueFromSource(this.currentUser, 'meta.email_verified', false)
    },
  },
  watch: {
    currentUser: {
      handler() {
        this.userData = cloneDeep(this.currentUser)
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.userData = cloneDeep(this.currentUser)
    this.avatarPath = this.getValueFromSource(this.currentUser, 'avatar.path', '');
  },
  methods: {
    async onUpdateUser() {
      try {
        this.loading = true;

        const formData = new FormData();
        
        formData.append("first_name", this.userData.first_name);
        formData.append("last_name", this.userData.last_name);
        formData.append("phone", this.userData.phone);
        formData.append("email", this.userData.email);

        const response = await this.useJwt().authService.updateProfile(formData);
        const updated_user = get(response, 'data.data');
        this.$store.commit(`auth/${MUTATE_USER_DATA}`, updated_user);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            variant: 'success',
            icon: 'CheckCircleIcon',
            text: `User updated successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onUpdateUserAvatar() {
      try {
        this.loading = true;

        const formData = new FormData();
  
        if (this.updatedImage) {
          formData.append("avatar", this.updatedImage);
        }

        const response = await this.useJwt().authService.updateProfile(formData);
        const updated_user = get(response, 'data.data');
        this.$store.commit(`auth/${MUTATE_USER_DATA}`, updated_user);

        if (this.updatedImage) {
          this.updatedImage = null;
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            variant: 'success',
            icon: 'CheckCircleIcon',
            text: `User avatar updated successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async resendAccountConfirmationEmail() {
      try {
        this.loading = true;
        await this.useJwt().authService.resendAccountConfirmationEmail({
          username: this.getValueFromSource(this.currentUser, 'email')
        });
        this.resent_account_confirmation_email = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Resent Email Confirmation Link",
            icon: "AlertTriangleIcon",
            variant: "success",
            text: "Email confirmation link sent successfully.",
          },
        });
      } catch (error) {
        this.blogPost = undefined;
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    onImageRenderer(file) {
     const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.avatarPath = reader.result
          this.updatedImage = file;
          this.onUpdateUserAvatar()
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    onAddNew(){
      this.isAddOrUpdateActive = true;
    },
    onTerms(){
      this.$router.push({
        name: 'terms',
      })
    },
    onPrivacy(){
      this.$router.push({
        name: 'privacy',
      })
    },
    showChat(){
      if (window.Tawk_API) {
        window.Tawk_API.maximize();
      } else {
        console.error("Tawk_API is not working.");
      }
    },
  }
}
</script>

