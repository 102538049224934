<template>
  <idle-display :duration="60 * idle_timeout">
    <div
      id="app"
      class="h-100"
      :class="[skinClasses]"
    >
      <component :is="layout" v-cloak>
        <router-view :key="$route.path" />
      </component>

      <scroll-to-top v-if="enableScrollToTop" />

      <div id="sound-source" style="display: none"></div>
    </div>
  </idle-display>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { get } from "lodash"
import { watch } from '@vue/composition-api'
import { useCssVar, useWindowSize } from '@vueuse/core'
import { provideToast } from 'vue-toastification/composition'
import { MUTATE_LOGIN_STATUS } from "@/store/config/mutation-keys"
import { $themeBreakpoints, $themeColors, $themeConfig } from '@themeConfig'

import store from '@/store'
import useAppConfig from '@core/app-config/useAppConfig'

import cars from '@core/mixins/cars';
import IdleDisplay from "@core/components/shared/idle/IdleDisplay.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')

export default {
  components: {
    IdleDisplay,

    // Layouts
    LayoutFull,
    LayoutVertical,
    LayoutHorizontal,

    ScrollToTop,
  },
  mixins: [cars],
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  data(){
    return {
      loading: false,
      loaded: false
    }
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      if (this.$route.meta.layout === 'horizontal') return 'layout-horizontal'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    idle_timeout() {
      return get(this.settings, 'security_settings.idle_timeout', 5)
    },
    authUIState() {
      return this.$store.state.ristic.authUIState
    }
  },
  watch: {
    isLoggedIn: {
      handler(userIsLoggedIn) {
        if (userIsLoggedIn && !this.$tawkMessenger.onLoaded()) {
          this.$tawkMessenger.$on('load', () => {
            this.updateTawkUserAttributes()
          })
        } else if (userIsLoggedIn && this.$tawkMessenger.onLoaded()) {
          this.updateTawkUserAttributes()
        }
      },
      immediate: true
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }


    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  destroyed() {
    this.$socket.client.close()
  },
  async created(){
    this.loaded = true;
  },
  mounted() {
    this.$nextTick(() => {
      const search = new URLSearchParams(window.location.search);
      const accountConfirmation = search.get('accountConfirmation')
      if (accountConfirmation) {
        this.confirmAccount(accountConfirmation);
      }
    })
  },
  methods: {
    async confirmAccount(token) {
      try {
        this.useJwt().authService.setAccountConfirmationToken(token);
        await this.useJwt().authService.confirmAccount(token);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success!",
            icon: "CheckCircleIcon",
            variant: "success",
            text: "Account confirmed. You can now login and enjoy our services.",
          },
        });
        this.$router.replace({ query: { accountConfirmation: null } });
      } catch (error) {
        console.log("ACCOUNT CONFIRMATION ERROR");
        const error_message = get(error, "response.data.message") || error.message;
        this.error_message = error_message || "Your account confirmation token may have expired. We'll send another to your email.";

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Account Confirmation Failed!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.refreshPage()  
      }
    },
    refreshPage() {
      this.$store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false)
      this.useJwt().authService.clearAccessToken();
      this.$router.push({
        name: 'app-root'
      })
    },
  },
}
</script>

<style scoped>
  [v-cloak] {
    display: none;
  }
</style>
