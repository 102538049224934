<template>
  <b-overlay :show="loadingCars">
    <section v-if="bestOfferCar" class="car-of-the-day-wrapper">
      <div
        class="d-flex align-items-center mt-5 justify-content-center my-5"
      >
        <best-car-of-the-day-ad 
          :best-offer="bestOfferCar"
        />
      </div>
    </section>

    <section v-else class="mb-5" style="background-color: blue;">
    </section>

    <section ref="carsDisplaySection">
      <div v-if="['xs', 'sm'].includes(currentBreakPoint)" class="px-3">
        <mobile-view-search-input />
      </div>

      <b-row class="px-3">
        <b-col v-if="!['xs', 'sm'].includes(currentBreakPoint)" cols="12" xl="2" lg="3" md="4" class="p-0 d-none d-md-block">
          <search-filter-sidebar 
            @searchCar="handleSearch"
          />
        </b-col>

        <b-col cols="12" xl="10" lg="9" md="6" sm="12">
          <b-row class="mb-1">
            <b-col cols="12" md="6" class="p-0">
              <div class="d-flex align-items-center" style="gap: 10px;">
                <feather-icon icon="GridIcon" size="25" class="cursor-pointer d-none d-md-block" :class="{ 'text-primary': listStyle === 'grid' }" @click="listStyle = 'grid'" />
                <feather-icon icon="ListIcon" size="25" class="cursor-pointer d-none d-md-block" :class="{ 'text-primary': listStyle === 'list' }" @click="listStyle = 'list'" />
              </div>
            </b-col>
            <b-col cols="12" md="6" class="p-0 d-flex justify-content-end">
              <div id="sorter">
                Sort by: 
                 <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                   <template #button-content>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-primary lucide lucide-arrow-down-up"><path d="m3 16 4 4 4-4" /><path d="M7 20V4" /><path d="m21 8-4-4-4 4" /><path d="M17 4v16" /></svg>
                    {{ sortByLabel }}
                  </template>
                  <b-dropdown-item v-for="sortOption of sortByOptions" :key="sortOption.value" href="#" @click="() => { sort_cars_by = sortOption.value }">{{ sortOption.label }}</b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
          <div>
            <b-row class="match-height">
              <b-row v-show="listStyle == 'grid'" class="match-height">
                <b-col v-for="(car, i) in carsAvailableForRental" :key="i" cols="12" md="3">
                  <car-display-card 
                    :car="car" 
                    :is-favorite="isFavoriteCar(car._id)"
                    @add-favorite="$car_id => onAddFavorite($car_id)"
                    @remove-favorite="$car_id => onRemoveFavorite($car_id)"
                  />
                </b-col>
              </b-row>

              <b-row v-show="listStyle == 'list'" class="match-height">
                <b-col v-for="(car, i) in carsAvailableForRental" :key="i" cols="12" md="6">
                  <horizontal-car-display-card :car="car" @add-favorite="$car_id => onAddFavorite($car_id)" @remove-favorite="$car_id => onRemoveFavorite($car_id)" />
                </b-col>
              </b-row>

              <LoadMore v-if="loadingExtraCars" />
            </b-row>
          </div>
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BLink,
  BCard,
  BButton,
  BOverlay,
  BCardText,
  BFormInput,
  BPagination,
  BSpinner,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
import { debounce } from "lodash";

import CarsMixin from "@/@core/mixins/cars";
import LottieAnimation from 'lottie-web-vue'
import LoadMore from "@/@core/components/shared/LoadMore.vue";
import CarDisplayCard from "@/@core/components/shared/CarDisplayCard.vue";
import BestCarOfTheDayAd from "@/@core/components/shared/BestCarOfTheDayAd.vue";
import SearchFilterSidebar from "@/@core/components/shared/SearchFilterSidebar.vue";
import MobileViewSearchInput from "@/@core/components/shared/MobileViewSearchInput.vue";
import HorizontalCarDisplayCard from "@/@core/components/shared/HorizontalCarDisplayCard.vue";

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BLink,
    BCard,
    BButton,
    BOverlay,
    LoadMore,
    BCardText,
    BFormInput,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    CarDisplayCard,
    BestCarOfTheDayAd,
    SearchFilterSidebar,
    LottieAnimation,
    MobileViewSearchInput,
    HorizontalCarDisplayCard,
  },
  mixins: [CarsMixin],
  data() {
    return {
      searchFilter: "",
      debouncedSearchCars: () => {},
      debounceFetchAllData: () => {},
      debounceHandleScroll: () => {},
      listStyle: "grid",
      sortByOptions: [
        {
          value: "created_desc",
          label: "Newest first",
        },
        {
          value: "created_asc",
          label: "Oldest first",
        },
        {
          value: "price_asc",
          label: "Lowest price first",
        },
        {
          value: "price_desc",
          label: "Highest price first",
        }
      ]
    };
  },
  computed: {
    displayComponent() {
      return this.listStyle === "grid" ? "CarDisplayCard" : "HorizontalCarDisplayCard";
    },
    columns() {
      return this.listStyle === "grid" ? "3" : "6";
    },
    sortByLabel() {
      const sortOption = this.sortByOptions.find(option => option.value === this.sort_cars_by)
      return sortOption ? sortOption.label : '';
    },
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    perPage: {
      handler() {
        this.currentPage = 1;
        this.debounceFetchAllData();
      },
      immediate: false,
    },
    filters: {
      deep: true,
      immediate: false,
      handler() {
        this.currentPage = 1
        this.fetchRentalCars();
      }
    },
    sort_cars_by: {
      handler(v) {
        console.log("sort by changed", v)
        this.fetchRentalCars();
      },
    }
  },
  mounted() {
    this.debounceHandleScroll = debounce(this.handleScroll, 200);
    this.debouncedSearchCars = debounce((query) => this.fetchRentalCars(query, false), 500);

    window.addEventListener("scroll", this.debounceHandleScroll);
    this.fetchBestOffers()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.debounceHandleScroll);
  },
  created() {
    this.debounceFetchAllData = debounce(this.fetchRentalCars, 500);
  },
  methods: {
    handleScroll() {
      const { carsDisplaySection } = this.$refs
      if (!carsDisplaySection) return;

      const { bottom } = carsDisplaySection.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (bottom <= viewportHeight && this.hasMoreCars) {
        this.currentPage += 1; 
        this.fetchRentalCars(this.searchFilter, true);
      }
    },
    handleSearch(searchValue) {
      this.searchFilter = searchValue
      this.currentPage = 1
      this.debouncedSearchCars(searchValue)
    },
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/app-home.scss";

#sorter .dropdown-toggle {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}
</style>
